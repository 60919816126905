import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { SocketProvider, useSocket } from './contexts/SocketContext';
import Home from './Home';
import Cases from './Cases';
import Payment from './Payment';
import Profile from './Profile';
import ChatButton from './components/ChatButton';
import ConversationModal from './components/ConversationModal';
import './styles/GenericStyles/footer.css'; // Importing your styles
import './styles/pageStyles/index/index.css'; // Importing your styles
import './styles/GenericStyles/slider.css'; // Importing your styles
import './styles/GenericStyles/button.css'; // Importing your styles
import './styles/GenericStyles/headline.css'; // Importing your styles
import './styles/GenericStyles/menu.css'; // Importing your styles
import './styles/GenericStyles/main.css'; // Importing your styles
import './styles/GenericStyles/LoginModal.css'; // Importing your styles
import './styles/GenericStyles/chatButton.css'; // Importing your styles
import './styles/GenericStyles/ConversationModal.css'; // Importing your styles

const App = () => {  
    return (
        <AuthProvider>
            <SocketProvider>
                <Router>
                    <AppContent />
                </Router>
            </SocketProvider>
        </AuthProvider>
    );
};


const AppContent = () => {
  const slideImages = [
    '/images/cases/case_1.webp',
    '/images/cases/case_2.webp',
    '/images/cases/case_3.webp',
    '/images/cases/case_4.webp',
    '/images/cases/case_5.webp',
    '/images/cases/case_1.webp',
    '/images/cases/case_2.webp',
    '/images/cases/case_3.webp',
    '/images/cases/case_4.webp',
    '/images/cases/case_5.webp',
    '/images/cases/case_1.webp',
    '/images/cases/case_2.webp',
    '/images/cases/case_3.webp',
    '/images/cases/case_4.webp',
    '/images/cases/case_5.webp',
    '/images/cases/case_1.webp',
    '/images/cases/case_2.webp',
    '/images/cases/case_3.webp',
    '/images/cases/case_4.webp',
    '/images/cases/case_5.webp',
    '/images/cases/case_1.webp',
    '/images/cases/case_2.webp',
    '/images/cases/case_3.webp',
    '/images/cases/case_4.webp',
    '/images/cases/case_5.webp',
    '/images/cases/case_1.webp',
    '/images/cases/case_2.webp',
    '/images/cases/case_3.webp',
    '/images/cases/case_4.webp',
    '/images/cases/case_5.webp',
    '/images/cases/case_1.webp',
    '/images/cases/case_2.webp',
    '/images/cases/case_3.webp',
    '/images/cases/case_4.webp',
    '/images/cases/case_5.webp',
    '/images/cases/case_1.webp',
    '/images/cases/case_2.webp',
    '/images/cases/case_3.webp',
    '/images/cases/case_4.webp',
    '/images/cases/case_5.webp',
    '/images/cases/case_1.webp',
    '/images/cases/case_2.webp',
    '/images/cases/case_3.webp',
    '/images/cases/case_4.webp',
    '/images/cases/case_5.webp',
    '/images/cases/case_1.webp',
    '/images/cases/case_2.webp',
    '/images/cases/case_3.webp',
    '/images/cases/case_4.webp',
    '/images/cases/case_5.webp',
    '/images/cases/case_1.webp',
    '/images/cases/case_2.webp',
    '/images/cases/case_3.webp',
    '/images/cases/case_4.webp',
    '/images/cases/case_5.webp',
    '/images/cases/case_1.webp',
    '/images/cases/case_2.webp',
    '/images/cases/case_3.webp',
    '/images/cases/case_4.webp',
    '/images/cases/case_5.webp',
    '/images/cases/case_1.webp',
    '/images/cases/case_2.webp',
    '/images/cases/case_3.webp',
    '/images/cases/case_4.webp',
    '/images/cases/case_5.webp',
    '/images/cases/case_1.webp',
    '/images/cases/case_2.webp',
    '/images/cases/case_3.webp',
    '/images/cases/case_4.webp',
    '/images/cases/case_5.webp',
  ]; 
  
  const casesData = [
    {
        id: 1,
        image: '/images/cases/case_1.webp',
        name: 'Case 1',
        price: '400 RUR',
    },
    {
        id: 2,
        image: '/images/cases/case_2.webp',
        name: 'Case 2',
        price: '450 RUR',
    },
    {
        id: 3,
        image: '/images/cases/case_3.webp',
        name: 'Case 3',
        price: '500 RUR',
    },
    {
        id: 1,
        image: '/images/cases/case_1.webp',
        name: 'Case 1',
        price: '400 RUR',
    },
    {
        id: 2,
        image: '/images/cases/case_2.webp',
        name: 'Case 2',
        price: '450 RUR',
    },
    {
        id: 3,
        image: '/images/cases/case_3.webp',
        name: 'Case 3',
        price: '500 RUR',
    },
    {
        id: 1,
        image: '/images/cases/case_1.webp',
        name: 'Case 1',
        price: '400 RUR',
    },
    {
        id: 2,
        image: '/images/cases/case_2.webp',
        name: 'Case 2',
        price: '450 RUR',
    },
    {
        id: 3,
        image: '/images/cases/case_3.webp',
        name: 'Case 3',
        price: '500 RUR',
    },
    {
        id: 1,
        image: '/images/cases/case_1.webp',
        name: 'Case 1',
        price: '400 RUR',
    },
    {
        id: 2,
        image: '/images/cases/case_2.webp',
        name: 'Case 2',
        price: '450 RUR',
    },
    {
        id: 3,
        image: '/images/cases/case_3.webp',
        name: 'Case 3',
        price: '500 RUR',
    },
    {
        id: 1,
        image: '/images/cases/case_1.webp',
        name: 'Case 1',
        price: '400 RUR',
    },
    {
        id: 2,
        image: '/images/cases/case_2.webp',
        name: 'Case 2',
        price: '450 RUR',
    },
    {
        id: 3,
        image: '/images/cases/case_3.webp',
        name: 'Case 3',
        price: '500 RUR',
    },
    {
        id: 1,
        image: '/images/cases/case_1.webp',
        name: 'Case 1',
        price: '400 RUR',
    },
    {
        id: 2,
        image: '/images/cases/case_2.webp',
        name: 'Case 2',
        price: '450 RUR',
    },
    {
        id: 3,
        image: '/images/cases/case_3.webp',
        name: 'Case 3',
        price: '500 RUR',
    },
    {
        id: 1,
        image: '/images/cases/case_1.webp',
        name: 'Case 1',
        price: '400 RUR',
    },
    {
        id: 2,
        image: '/images/cases/case_2.webp',
        name: 'Case 2',
        price: '450 RUR',
    },
    {
        id: 3,
        image: '/images/cases/case_3.webp',
        name: 'Case 3',
        price: '500 RUR',
    },
    // Add more cases as needed
];
    const { user, setUser } = useAuth(); 
    const socket = useSocket();
    const [isChatOpen, setIsChatOpen] = useState(false);
    const [conversationId, setConversationId] = useState(null);
    const [hasUnreadMessages, setHasUnreadMessages] = useState(false);

    useEffect(() => {
        if (!socket || !user) return;

        const handleReceiveMessage = (data) => {
            const { conversation_id, isOperator, isViewed } = data;

            if (isOperator && !isViewed) {
                setHasUnreadMessages(true);
                setConversationId(conversation_id);
            }
        };

        // Attach socket event listener for receiving messages
        socket.on('receive_message', handleReceiveMessage);

        // Handle reconnection
        socket.on('connect', () => {
            console.log("Socket reconnected:", socket.id);
        });

        return () => {
            socket.off('receive_message', handleReceiveMessage);
        };
    }, [socket, user]);

    // Manage socket connection for chat modal opening
    useEffect(() => {
        if (isChatOpen && socket) {
            // Emit to join conversation room when chat is open
            if (conversationId) {
                socket.emit('join_conversation', { conversation_id: conversationId });
            }

            return () => {
                if (conversationId) {
                    socket.emit('leave_conversation', { conversation_id: conversationId });
                }
            };
        }
    }, [isChatOpen, conversationId, socket]);

    const openChat = () => {
        setIsChatOpen(true);
        setHasUnreadMessages(false);
    };

    const closeChat = () => {
        setIsChatOpen(false);
    };

    if (!socket) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <Routes>
                <Route path="/" element={<Home slideImages={slideImages} casesData={casesData} />}/>
                <Route path="/cases" element={<Cases />} />
                <Route path="/payment" element={<Payment slideImages={slideImages} />} />
                <Route path="/profile" element={<Profile slideImages={slideImages} />} />
            </Routes>
            {user && (
                <>
                    <ChatButton onClick={openChat} hasUnreadMessages={hasUnreadMessages} />
                    <ConversationModal
                        isOpen={isChatOpen}
                        onClose={closeChat}
                        conversationId={conversationId}
                        setConversationId={setConversationId}
                    />
                </>
            )}
        </>
    );
};

export default App;
