import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';  // Assuming you have an AuthContext to manage user data
import { useNavigate } from 'react-router-dom';  // To navigate if the user is not logged in

const UserProfile = () => {
    const { user, isLoggedIn } = useAuth();  // Accessing user data and login status from AuthContext
    const [casesData, setCasesData] = useState([]); // State to store the user's cases
    const [itemsData, setItemsData] = useState([]); // State to store the user's items
    const [userData, setUserData] = useState({
        steamId: '',
        username: '',
        avatar: '',
        balance: 0,  // Default balance
    });
    const [loading, setLoading] = useState(true);  // Loading state to manage fetching process
    const [activeTab, setActiveTab] = useState(1);  // Track which tab is active (1: cases, 2: items)
    const navigate = useNavigate();  // To navigate if the user is not logged in

    // If the user is not logged in, redirect to the login page
    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/');  // Redirect to home if not logged in
        } else if (user) {
            // If the user is logged in, set the user data state
            setUserData(user);
        }
    }, [isLoggedIn, user, navigate]);  // Re-run when user data or login state changes

    // Fetch case data based on the user's case IDs when the first tab is active
    useEffect(() => {
        if (activeTab === 1 && user && user.cases && user.cases.length > 0) {
            const fetchCases = async () => {
                setLoading(true);  // Start loading
                try {
                    // Fetch case data based on the case IDs in the user's cases array
                    const caseIds = user.cases;
                    const response = await fetch(`/api/get-cases-by-ids?case_ids=${caseIds.join(',')}`);
                    const data = await response.json();

                    if (data.error) {
                        console.error(data.error);
                    } else {
                        setCasesData(data);  // Set the fetched case data
                    }
                } catch (error) {
                    console.error('Error fetching cases:', error);
                } finally {
                    setLoading(false);  // Stop loading after the data is fetched
                }
            };

            fetchCases();
        }
    }, [activeTab, user]);  // Re-run when the active tab or user data changes

    // Fetch item data based on the user's item IDs when the second tab is active
    useEffect(() => {
        console.log(user);
        
        if (activeTab === 2 && user && user.additional_inventory && user.additional_inventory.length > 0) {
            const fetchItems = async () => {
                setLoading(true);  // Start loading
                try {
                    // Fetch item data based on the item IDs in the user's items array
                    const itemIds = user.additional_inventory;
                    console.log("🚀 ~ fetchItems ~ itemIds:", itemIds)
                    const response = await fetch(`/api/get-items-by-ids?item_ids=${itemIds.join(',')}`);
                    const data = await response.json();

                    if (data.error) {
                        console.error(data.error);
                    } else {
                        setItemsData(data);  // Set the fetched item data
                    }
                } catch (error) {
                    console.error('Error fetching items:', error);
                } finally {
                    setLoading(false);  // Stop loading after the data is fetched
                }
            };

            fetchItems();
        }
    }, [activeTab, user]);  // Re-run when the active tab or user data changes

    // If the user is not logged in or data is loading, display loading indicator
    if (loading || !isLoggedIn) {
        return <div>Loading...</div>;  // You can customize this loading indicator
    }

    return (
        <div className="user-profile-section">
            <div className="user-profile-card">
                <div className="user-card">
                    <div className="user-avatar">
                        <img src={userData.avatar} alt={userData.username} className="avatar-img" />
                    </div>
                    <div className="user-details">
                        <div className="user-name">{userData.username}</div>
                        <button className="level-up-btn">Сгенерировать уровень</button>
                        <div className="progress-container">
                            <div className="progress-bar" style={{ width: '50%' }}>70%</div>
                        </div>
                        <div className="experience-info">
                            <span>0/50 XP</span>
                            <span className="how-it-works">Как это работает?</span>
                        </div>
                    </div>
                </div>
                <div className="user-balances">
                    <div className="balance">{userData.balance} ₽</div>
                    <div className="balance">0 Монеты</div>
                    <button className="fill-ballance">Пополнить баланс</button>
                </div>
                <div className="account-info">
                    <div className="account-status">Аккаунт не подтвержден</div>
                    <button className="confirm-account-btn">Подтвердить аккаунт</button>
                    <button className="partner-program-btn">Партнерская программа...</button>
                </div>
            </div>

            <div className="tab-buttons">
                <button
                    className={`tab-btn ${activeTab === 1 ? 'active' : ''}`}
                    onClick={() => setActiveTab(1)}
                >
                    Новые 2
                </button>
                <button
                    className={`tab-btn ${activeTab === 2 ? 'active' : ''}`}
                    onClick={() => setActiveTab(2)}
                >
                    Старые 2
                </button>
            </div>

            <div className="items-display">
                {/* Display data based on the active tab */}
                {activeTab === 1 && casesData.length > 0 ? (
                    casesData.map((caseItem, index) => (
                        <div className="item-container" key={index}>
                            <div className="image-container">
                                <img src={caseItem.image_url} alt={caseItem.name} />
                            </div>
                            <div className="info">
                                <h3>{caseItem.name}</h3>
                                <p>Price: {caseItem.price} ₽</p>
                            </div>
                        </div>
                    ))
                ) : activeTab === 2 && itemsData.length > 0 ? (
                    itemsData.map((item, index) => (
                        <div className="item-container" key={index}>
                            <div className="image-container">
                                <img src={item.image_url} alt={item.name} />
                            </div>
                            <div className="info">
                                <h3>{item.name}</h3>
                                <p>{item.aguility}</p>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No data found. Please check your list.</p>
                )}
            </div>
        </div>
    );
};

export default UserProfile;
