import React from 'react';
import '../styles/GenericStyles/chatButton.css';

const ChatButton = ({ onClick }) => {
    return (
        <button className="chat-button" onClick={onClick}>
            Open Chat
        </button>
    );
};

export default ChatButton;

// CSS for Chat Button
