import React, { createContext, useContext, useState, useEffect } from 'react';
import { io } from 'socket.io-client';

const SocketContext = createContext(null);

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const newSocket = io('wss://opencase123.ru', {
      path: "/socket.io/",
      transports: ['websocket'], // Use websocket instead of the default polling
    });
    console.log("here");
    
    setSocket(newSocket);

    // Cleanup function to disconnect socket on unmount
    return () => newSocket.close();
  }, []);

  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => {
    console.log("here");
  const context = useContext(SocketContext);
  console.log("🚀 ~ useSocket ~ context:", context)
  if (context === null) {
    // throw new Error("useSocket must be used within a SocketProvider");
  }
  return context;
};
