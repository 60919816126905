import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useSocket } from '../contexts/SocketContext';
import '../styles/GenericStyles/ConversationModal.css';
import FocusTrap from 'focus-trap-react';

const ConversationModal = ({ isOpen, onClose, conversationId, setConversationId }) => {
    const [messages, setMessages] = useState([]);
    const [reply, setReply] = useState("");
    const [loading, setLoading] = useState(false);
    const { user } = useAuth();
    const socket = useSocket();
    const hasFetchedInitialMessages = useRef(false);
    const [dependentQuestions, setDependentQuestions] = useState([]); // NEW state for dependent questions
    const modalRef = useRef(null);
    const headerRef = useRef(null);
    const conversationIdRef = useRef(null);

    // Wrapper function to update both state and ref for conversation ID
    const setConversationIdWithRef = (id) => {
        setConversationId(id);
        conversationIdRef.current = id;
    };

    // Reset state when modal is closed
    useEffect(() => {
        if (!isOpen) {
            setMessages([]);
            setReply("");
            setDependentQuestions([]);
            hasFetchedInitialMessages.current = false;
        }
    }, [isOpen]);

    // Fetch initial data when modal opens
    useEffect(() => {
        const fetchUserConversations = async () => {
            try {
                const userResponse = await fetch(`/user/${user.id}`);
                if (!userResponse.ok) {
                    throw new Error('Failed to fetch user data');
                }
                const userData = await userResponse.json();

                const conversationIds = userData.conversations;

                if (conversationIds && conversationIds.length > 0) {
                    let activeConversationFound = false;

                    for (const conversationId of conversationIds) {
                        const conversationResponse = await fetch(`/api/get-conversation/${conversationId}`);
                        if (!conversationResponse.ok) {
                            throw new Error(`Failed to fetch conversation with ID: ${conversationId}`);
                        }
                        const conversation = await conversationResponse.json();

                        if (conversation.status === 0) {
                            await fetchMessages(conversationId);
                            setConversationIdWithRef(conversationId);
                            activeConversationFound = true;
                            break;
                        }
                    }

                    if (!activeConversationFound) {
                        fetchMainQuestions();
                    }
                } else {
                    fetchMainQuestions();
                }
            } catch (error) {
                console.error('Error fetching user conversations:', error);
            }
        };

        if (isOpen && !hasFetchedInitialMessages.current) {
            fetchUserConversations();
            hasFetchedInitialMessages.current = true;
        }
    }, [isOpen, user]);

    // Set focus when the modal opens
    useEffect(() => {
        if (isOpen && modalRef.current) {
            requestAnimationFrame(() => {
                if (modalRef.current) {
                    modalRef.current.focus();
                }
                if (headerRef.current) {
                    headerRef.current.click();
                }
                headerRef.current.focus();
            });
        }
    }, [isOpen]);

    // Enforce focus within the modal
    useEffect(() => {
        if (isOpen && modalRef.current) {
            const handleFocus = (event) => {
                if (modalRef.current && !modalRef.current.contains(event.target)) {
                    event.preventDefault();
                    modalRef.current.focus();
                }
            };

            document.addEventListener('focusin', handleFocus);
            return () => {
                document.removeEventListener('focusin', handleFocus);
            };
        }
    }, [isOpen]);

    // Fetch messages for an existing conversation
    const fetchMessages = useCallback(async (conversationId) => {
        try {
            const response = await fetch(`/api/get-conversation/${conversationId}`);
            if (!response.ok) {
                throw new Error('Failed to fetch conversation messages');
            }
            const data = await response.json();

            const formattedMessages = data.messages.map((msg) => ({
                type: msg.isOperator ? 'answer' : 'user-message',
                text: msg.message,
                id: msg.id,
                isViewed: msg.isViewed,
            }));
            setMessages(formattedMessages);
        } catch (error) {
            console.error('Error fetching conversation messages:', error);
        }
    }, []);

    // Fetch main questions when no active conversation exists
      // Fetch main questions when no active conversation exists
      const fetchMainQuestions = useCallback(async () => {
        try {
            const response = await fetch('/api/get-main-questions');
            if (!response.ok) {
                throw new Error('Failed to fetch main questions');
            }
            const data = await response.json();

            const mainQuestions = data.map(q => ({
                type: 'question',
                text: q.question,
                id: q.id,
            }));
            setDependentQuestions(mainQuestions); // Store in dependent questions
        } catch (error) {
            console.error('Error fetching main questions:', error);
        }
    }, []);

// Handle question click
const handleQuestionClick = async (questionId, questionText) => {
    if (loading) {
        console.log('Click ignored, request is still being processed.');
        return;
    }

    setLoading(true);

    try {
        let currentConversationId = conversationIdRef.current;

        if (!currentConversationId) {
            const createResponse = await fetch('/api/create-conversation', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_id: user.id }),
            });

            if (!createResponse.ok) {
                throw new Error('Failed to create a new conversation');
            }

            const createData = await createResponse.json();
            currentConversationId = createData.conversation_id;
            setConversationIdWithRef(currentConversationId);
        }

        // Hide dependent questions when one is selected
        setDependentQuestions([]);

        // Save user's question to the backend
        await fetch('/api/add-message', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                conversation_id: currentConversationId,
                message: questionText,
                isOperator: false,
            }),
        });

        await fetchMessages(currentConversationId);

        // Fetch the answer to the selected question
        const response = await fetch(`/api/get-question/${questionId}`);
        if (!response.ok) {
            throw new Error('Failed to fetch question details');
        }

        const data = await response.json();

        // Store answer in backend
        await fetch('/api/add-message', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                conversation_id: currentConversationId,
                message: data.answer,
                isOperator: true,
            }),
        });

        await fetchMessages(currentConversationId);

        // If there are dependent questions, set them in the state
        if (data.dependantQuestionsIds && data.dependantQuestionsIds.length > 0) {
            const dependentResponse = await fetch('/api/get-questions-by-ids', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ questionIds: data.dependantQuestionsIds }),
            });

            if (!dependentResponse.ok) {
                throw new Error('Failed to fetch dependent questions');
            }

            const dependentData = await dependentResponse.json();

            const dependentQuestions = dependentData.map((q) => ({
                type: 'question',
                text: q.question,
                id: q.id,
            }));

            setDependentQuestions(dependentQuestions);
        } else {
            // No more dependent questions, fetch the full conversation
            console.log('No more dependent questions, fetching complete conversation...');
            await fetchMessages(currentConversationId);
        }
    } catch (error) {
        console.error('Error creating or fetching conversation:', error);
    } finally {
        setLoading(false);
    }
};

    // Send custom message via socket
    const sendMessage = () => {
        const currentConversationId = conversationIdRef.current;

        if (reply.trim() !== "" && currentConversationId) {
            setLoading(true);

            const messageData = {
                conversation_id: currentConversationId,
                message: reply,
                isOperator: false,
            };

            if (socket && socket.connected) {
                console.log('Socket is connected, sending message:', messageData);
                socket.emit('user_send_message', messageData);
            } else {
                console.error('Socket is not connected, cannot send message');
                setLoading(false);
                return;
            }

            // setMessages(prevMessages => [
            //     ...prevMessages,
            //     { type: 'user-message', text: reply, id: Date.now() }
            // ]);
            console.log("send");
            
            setReply("");
            setLoading(false);
        } else {
            console.error('Conversation ID is missing or reply is empty');
        }
    };

    const finishConversation = () => {
        if (conversationIdRef.current) {
            fetch(`/api/finish-conversation/${conversationIdRef.current}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    if (response.ok) {
                        console.log('Conversation finished successfully');
                        setMessages([]);
                        setConversationIdWithRef(null);
                        hasFetchedInitialMessages.current = false;
                    } else {
                        console.error('Failed to finish conversation');
                    }
                })
                .catch(error => console.error('Error finishing conversation:', error));
        }
    };

    useEffect(() => {
        if (socket && isOpen) {
            const joinRoom = () => {
                if (conversationIdRef.current) {
                    console.log(`Joining conversation room: ${conversationIdRef.current}`);
                    socket.emit('join_conversation', { conversation_id: conversationIdRef.current });
                }
            };
    
            // Join room when socket connects or reconnects
            socket.on('connect', joinRoom);
            joinRoom(); // Join immediately if connected
    
    // Handle incoming messages
    const handleReceiveMessage = (data) => {
        console.log("Received message:", data);
        if (data.conversation_id === conversationIdRef.current) {
            setMessages((prevMessages) => {
                // Check if the message already exists in the state
                const isMessageAlreadyAdded = prevMessages.some(
                    (msg) => msg.id === data.message_id
                );

                if (isMessageAlreadyAdded) {
                    return prevMessages; // Return the current state if the message is already there
                }

                return [
                    ...prevMessages,
                    {
                        type: data.isOperator ? 'answer' : 'user-message',
                        text: data.message,
                        id: data.message_id,
                    },
                ];
            });
        }
    };
    
            socket.on('receive_message', handleReceiveMessage);
    
            // Cleanup when the component unmounts or modal is closed
            return () => {
                socket.off('connect', joinRoom);
                socket.off('receive_message', handleReceiveMessage);
    
                if (conversationIdRef.current) {
                    console.log(`Leaving conversation room: ${conversationIdRef.current}`);
                    socket.emit('leave_conversation', { conversation_id: conversationIdRef.current });
                }
            };
        }
    }, [socket, isOpen, conversationId]);

    if (!isOpen) return null;

    return (
        <FocusTrap>
            <div
                className="chat-modal"
                ref={modalRef}
                tabIndex={-1}
            >
                <div className="chat-modal-content">
                    <span className="close-chat-button" onClick={onClose}>&times;</span>
                    <h2 ref={headerRef}>Support Chat</h2>
                    <div className="chat-conversation">
                        {messages.map((msg, index) => (
                            <div key={msg.id || index} className={`message-${msg.type}`}>
                                {msg.type === 'user-message' && `You: `}
                                {msg.type === 'answer' && `Support: `}
                                {msg.text}
                            </div>
                        ))}
                    </div>
                    <div className="chat-questions">
                        {dependentQuestions.map((question) => (
                            <div key={question.id} className="question" onClick={() => handleQuestionClick(question.id, question.text)}>
                                {question.text}
                            </div>
                        ))}
                    </div>
                    <div className="chat-reply-section">
                        <textarea
                            value={reply}
                            onChange={(e) => setReply(e.target.value)}
                            placeholder="Type your message here..."
                            disabled={loading}
                        ></textarea>
                        <button onClick={sendMessage} className="chat-send-button" disabled={loading}>Send</button>
                        <button onClick={finishConversation} className="chat-finish-button" disabled={loading}>Finish Conversation</button>
                    </div>
                </div>
            </div>
        </FocusTrap>
    );
};

export default ConversationModal;
