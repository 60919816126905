import LoginModal from './LoginModal'; // Import the LoginModal component
import axios from 'axios';  // You'll need axios to make HTTP requests
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';  // Correct import of useAuth

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Get the URL search params
  const { isLoggedIn, login, logout, user } = useAuth(); // Get authentication state and functions
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [userData, setUserData] = useState(null);  // State to store fetched user data
  const [loading, setLoading] = useState(false);  // Loading state for async operations

  const handleLoginFromURL = () => {
    const params = new URLSearchParams(location.search);
    const id = params.get('id');
    
    if (id && !user) { // Fetch user data only if user is null
      fetchUserData(id);
    }
  };

  const fetchUserData = async (id) => {
    setLoading(true);
    try {
      const response = await axios.get(`/user/${id}`);  // Make an API request to fetch user data from DB
      if (response.data) {
        setUserData(response.data);  // Update the user data state with the fetched data
        console.log("🚀 ~ fetchUserData ~ response.data:", response.data);
        
        // Once user data is fetched, login with the data
        login(response.data);
        // Clean up URL after login
        // const urlWithoutParams = window.location.pathname;
        // window.history.replaceState({}, document.title, urlWithoutParams);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Handle login redirect and update user state
    handleLoginFromURL();  // Check for steam_id, username, and avatar in the URL when it changes
  }, [location.search]);  // When the search params change (i.e., after redirect)

  const handleHomeClick = () => {
    navigate('/'); // Redirect to the home route
  };

  const handleCasesClick = () => {
    navigate('/cases'); // Redirect to the cases route
  };

  const handlePaymentClick = () => {
    navigate('/payment'); // Redirect to the payment route
  };

  const handleProfileClick = () => {
    navigate('/profile'); // Redirect to the profile route
  };

  // Function to show login modal
  const handleLoginClick = () => {
    setShowModal(true); // Show the login modal
  };

  // Close the modal
  const closeModal = () => {
    setShowModal(false); // Close the modal
  };

  // Handle logout
  const handleLogout = () => {
    logout(); // Set login state to false
    setUserData(null);  // Clear user data when logging out
    navigate('/'); // Redirect to home after logging out
  };

  useEffect(() => {
    if (user) {
      console.log(user);
      
      setUserData(user);
    }
  }, [user]);

  return (
    <header className="content-header">
      <div className="logo">
        <img className="main-logo" src="/images/dota2.webp" alt="Game Logo" />
      </div>
      <div className="menu-container">
        <a href="#!" onClick={handleHomeClick}>
          <div className="common-button">
            <img src="/images/menu/home.webp" alt="Home" />
          </div>
        </a>
        <a href="#!" onClick={handleCasesClick}>
          <div className="common-button">
            <img src="/images/menu/cases.webp" alt="Cases" />
          </div>
        </a>
        <div className="common-button">
          <img src="/images/menu/contracts.webp" alt="Contracts" />
        </div>
        <div className="common-button">
          <img src="/images/menu/upgrades.webp" alt="Upgrades" />
        </div>
      </div>

      <div className="profile-section">
        {isLoggedIn && userData ? (
          <>
            {/* Profile View */}
            <button className="balance-button" onClick={handlePaymentClick}>
              Пополнить баланс
            </button>
            <div className="avatar">
              <img src={userData.avatar_url} alt={userData.name} />
            </div>
            <div className="user-info">
              <div className="username">
                <a href="#!" onClick={handleProfileClick}>{userData.name}</a>
              </div>
              <div className="balances">
                <div>{userData.balance} ₽</div>
              </div>
            </div>
            <div className="logout-image" onClick={handleLogout}>
              <img src="images/logout.png" alt="Logout" />
            </div>
          </>
        ) : (
          // Login Button
          <button className="common-button-login" onClick={handleLoginClick}>
            <span>ВОЙТИ</span>
          </button>
        )}
      </div>

      {/* Show Login Modal if showModal is true */}
      {showModal && <LoginModal closeModal={closeModal} />}
    </header>
  );
};

export default Header;
